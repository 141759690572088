import { Form } from 'antd';
import styled from 'styled-components';
import { BlueColors, NeutralColors } from 'theme';

const EnabledBox = styled.div``;

const SwitchBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;

  padding: 16px;
  margin-top: 24px;
  border-radius: 12px;
  border: 1px solid ${NeutralColors[20]};

  .fms-switch-checked {
    background-color: ${BlueColors[60]};
    :hover {
      background-color: ${BlueColors[60]} !important;
    }
  }
`;
const SwitchForm = styled(Form.Item)`
  margin: 0;
`;

export { EnabledBox, SwitchBox, SwitchForm };
