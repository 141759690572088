export enum StatusEnum {
  Enabled = 'enabled',
  Disabled = 'disabled',
}

export const StatusTranslationEnum = {
  [StatusEnum.Enabled]: 'enabled',
  [StatusEnum.Disabled]: 'disabled',
};

export const statusColor = {
  [StatusEnum.Enabled]: 'green',
  [StatusEnum.Disabled]: 'default',
};
