import { Table as TableAntd } from 'antd';
import styled from 'styled-components';
import { NeutralColors } from 'theme';

const Table = styled(TableAntd)`
  tr {
    background-color: ${NeutralColors[10]};
  }

  td {
    border-bottom: 1px solid ${NeutralColors[20]};
  }
`;

export { Table };
