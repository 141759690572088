import { UseDrawerFormReturnType } from '@refinedev/antd';
import { Table, TableProps } from 'antd';
import { CurrencyValue } from 'common/components';
import { ROUTES } from 'common/constants';
import {
  ArrowRightIcon,
  ChevronBottomIcon,
  ChevronTopIcon,
} from 'common/icons';
import { useNavigateWithOrgAndFarm } from 'common/utils';
import { RenderExpandIcon } from 'rc-table/lib/interface';
import { NeutralColors } from 'theme';

import { ElectronicInvoiceEnum } from '../electronic-invoice.enum';
import { TypeFilter } from '../electronic-invoice.hook';
import { ElectronicInvoiceGeneric } from '../electronic-invoice.model';
import * as Styled from './electronic-invoice-generic.style';
import { ElectronicInvoiceList } from './electronic-invoice-list/electronic-invoice-list.component';
import { ElectronicInvoiceNameColumn } from './electronic-invoice-name-column/electronic-invoice-name-column.component';

interface ElectronicInvoiceGenericContainerProps {
  drawerForm: UseDrawerFormReturnType;
  referenceId?: string;
  statusFilter: string[];
  tablePropsGeneric: TableProps<ElectronicInvoiceGeneric>;
  currentFilter: string;
  refetch: () => void;
}

const ElectronicInvoiceGenericContainer = ({
  drawerForm,
  referenceId,
  statusFilter,
  tablePropsGeneric,
  currentFilter,
  refetch,
}: ElectronicInvoiceGenericContainerProps) => {
  const navigate = useNavigateWithOrgAndFarm();

  if (referenceId) {
    return null;
  }

  const handleGoToElectronicInvoiceLink = (idsElectronicInvoice: string[]) => {
    navigate(ROUTES.ELECTRONIC_INVOICE_LINK, undefined, undefined, undefined, {
      idsElectronicInvoice,
    });
  };

  const handleRenderExpandIcon: RenderExpandIcon<ElectronicInvoiceGeneric> = ({
    expanded,
    onExpand,
    record,
  }) =>
    expanded ? (
      <ChevronTopIcon
        onClick={(e: React.MouseEvent<HTMLElement>) => onExpand(record, e)}
      />
    ) : (
      <ChevronBottomIcon
        onClick={(e: React.MouseEvent<HTMLElement>) => onExpand(record, e)}
      />
    );

  return (
    <Table
      {...tablePropsGeneric}
      showHeader={false}
      rowKey={'name'}
      style={{
        border: `1px solid ${NeutralColors[20]}`,
        borderRadius: '14px',
      }}
      expandable={{
        expandedRowRender: (electronicInvoiceGeneric) => (
          <ElectronicInvoiceList
            drawerForm={drawerForm}
            electronicInvoiceGeneric={electronicInvoiceGeneric}
            statusFilter={statusFilter}
            currentFilter={currentFilter}
            refetch={refetch}
          />
        ),
        expandIcon: handleRenderExpandIcon,
      }}
      data-testid="electronic-invoice-generic-table"
    >
      <Table.Column
        render={(electronicInvoiceList: ElectronicInvoiceGeneric) => (
          <ElectronicInvoiceNameColumn
            electronicInvoiceList={electronicInvoiceList}
          />
        )}
      />
      <Table.Column
        width={200}
        render={(electronicInvoiceList: ElectronicInvoiceGeneric) => (
          <CurrencyValue value={electronicInvoiceList.totalGrouped} />
        )}
      />
      {currentFilter === TypeFilter.Pending && (
        <Table.Column
          width={160}
          render={(electronicInvoiceList: ElectronicInvoiceGeneric) => {
            const idsElectronicInvoice = electronicInvoiceList?.invoices
              ?.filter(
                (electronicInvoiceList) =>
                  electronicInvoiceList.status !== ElectronicInvoiceEnum.FAILED,
              )
              .map((invoice) => invoice.id);

            return (
              <Styled.ButtonsContainer>
                <Styled.Button
                  type="primary"
                  onClick={() =>
                    handleGoToElectronicInvoiceLink(idsElectronicInvoice)
                  }
                  disabled={idsElectronicInvoice.length === 0}
                >
                  <ArrowRightIcon
                    style={{
                      fill:
                        idsElectronicInvoice.length === 0
                          ? NeutralColors[40]
                          : NeutralColors[0],
                    }}
                  />
                </Styled.Button>
              </Styled.ButtonsContainer>
            );
          }}
          data-testid="actions-column"
        />
      )}
    </Table>
  );
};

export { ElectronicInvoiceGenericContainer };
