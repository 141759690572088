import { UseDrawerFormReturnType, useTable } from '@refinedev/antd';
import { Table } from 'antd';
import { CurrencyValue } from 'common/components';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { ActionsElectronicInvoice } from 'src/orders-and-payments/electronic-invoice/actions-electronic-invoice/actions-electronic-invoice.component';
import { ElectronicInvoiceEnum } from 'src/orders-and-payments/electronic-invoice/electronic-invoice.enum';
import { TypeFilter } from 'src/orders-and-payments/electronic-invoice/electronic-invoice.hook';
import { ElectronicInvoiceGeneric } from 'src/orders-and-payments/electronic-invoice/electronic-invoice.model';
import { ElectronicInvoiceNumberColumn } from 'src/orders-and-payments/electronic-invoice/electronic-invoice-number-column/electronic-invoice-number-column.component';
import { FailedColumn } from 'src/orders-and-payments/electronic-invoice/failed-column/failed-column.component';
import { ItemsColumn } from 'src/orders-and-payments/electronic-invoice/items-column/items-column.component';
import { useOrganization } from 'src/organization';

import * as Styled from './electronic-invoice-list.style';

interface ElectronicInvoiceListProps {
  electronicInvoiceGeneric: ElectronicInvoiceGeneric;
  drawerForm: UseDrawerFormReturnType;
  statusFilter: string[];
  currentFilter: string;
  refetch: () => void;
}

const ElectronicInvoiceList = ({
  drawerForm,
  currentFilter,
  electronicInvoiceGeneric,
  statusFilter,
  refetch,
}: ElectronicInvoiceListProps) => {
  const { currentOrganizationId } = useOrganization();

  const metadata = useMemo(
    () => ({
      organizationId: currentOrganizationId,
    }),
    [currentOrganizationId],
  );

  const { tableProps } = useTable<any>({
    resource: `electronic-invoice/hall/cnpjCpf/${electronicInvoiceGeneric.cnpjCpf}/invoices`,
    pagination: {
      pageSize: 99999999,
    },
    filters: {
      permanent: [
        {
          field: 'status',
          operator: 'eq',
          value: statusFilter,
        },
      ],
    },
    meta: {
      ...metadata,
    },
  });

  return (
    <Styled.Table
      {...tableProps}
      pagination={false}
      showHeader={false}
      rowKey="id"
      data-testid="electronic-invoice-list-table"
    >
      <Table.Column
        render={({ invoiceDate, status, failedMessage }) =>
          status === ElectronicInvoiceEnum.FAILED ? (
            <FailedColumn failedMessage={failedMessage} />
          ) : (
            dayjs(invoiceDate).format('DD/MM/YYYY')
          )
        }
        onCell={({ status }: any) => ({
          colSpan: status === ElectronicInvoiceEnum.FAILED ? 4 : 1,
        })}
      />
      <Table.Column
        render={({ itemsQty }) => <ItemsColumn itemsQty={itemsQty} />}
        onCell={({ status }: any) => ({
          colSpan: status === ElectronicInvoiceEnum.FAILED ? 0 : 1,
        })}
      />
      <Table.Column
        render={({ externalId }) => (
          <ElectronicInvoiceNumberColumn externalId={externalId} />
        )}
        onCell={({ status }: any) => ({
          colSpan: status === ElectronicInvoiceEnum.FAILED ? 0 : 1,
        })}
      />
      <Table.Column
        width={200}
        render={({ totalValue }) => <CurrencyValue value={totalValue} />}
        onCell={({ status }: any) => ({
          colSpan: status === ElectronicInvoiceEnum.FAILED ? 0 : 1,
        })}
      />
      <Table.Column
        width={160}
        render={({ id, status }) => (
          <ActionsElectronicInvoice
            id={id}
            blockImport={currentFilter !== TypeFilter.Pending}
            status={status}
            showAll={currentFilter === TypeFilter.Pending}
            drawerForm={drawerForm}
            refetch={refetch}
          />
        )}
      />
    </Styled.Table>
  );
};

export { ElectronicInvoiceList };
