import { Input } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import { CheckIcon, EditIcon } from 'common/icons';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PurchaseOrderNumberTestId } from 'src/orders-and-payments/electronic-invoice/electronic-invoice-test-id.constants';

import * as Styled from './purchase-order-number.style';

const PurchaseOrderNumber = () => {
  const purchaseNumber = useWatch(['purchaseNumber']);
  const [openEdit, setOpenEdit] = useState(false);
  const { t } = useTranslation();
  const handleChangeOpenEdit = () => {
    setOpenEdit(!openEdit);
  };

  return (
    <Styled.Box isSuccess={!!purchaseNumber}>
      <Styled.FormItem
        name={['purchaseNumber']}
        rules={[
          {
            required: true,
          },
        ]}
      >
        {openEdit ? (
          <Input
            data-testid={PurchaseOrderNumberTestId.PURCHASE_ORDER_NUMBER_INPUT}
          />
        ) : (
          <Styled.BoxWarehouse
            isSuccess={!!purchaseNumber}
            onClick={handleChangeOpenEdit}
          >
            {purchaseNumber ? (
              <>
                <CheckIcon />
                {`${t(
                  'electronic_invoice.purchase_order.number',
                )}: ${purchaseNumber}`}
              </>
            ) : (
              <>{t('electronic_invoice.purchase_order.number_question')}</>
            )}
          </Styled.BoxWarehouse>
        )}
      </Styled.FormItem>
      <Styled.EditButton
        data-testid={PurchaseOrderNumberTestId.PURCHASE_ORDER_NUMBER_BUTTON}
        onClick={handleChangeOpenEdit}
      >
        {openEdit ? <CheckIcon /> : <EditIcon />}
      </Styled.EditButton>
    </Styled.Box>
  );
};

export { PurchaseOrderNumber };
