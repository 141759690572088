import { useSelect } from '@refinedev/antd';
import { Typography } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { SelectWithButton } from 'common/components';
import { CheckIcon, EditIcon, HelpIcon } from 'common/icons';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PurchaseOrderFarmTestId } from 'src/orders-and-payments/electronic-invoice/electronic-invoice-test-id.constants';
import { useFarm, useOrganization } from 'src/organization';

import * as Styled from './purchase-order-farm.style';

interface PurchaseOrderFarmProps {
  address?: string;
}

const PurchaseOrderFarm = ({ address }: PurchaseOrderFarmProps) => {
  const farm = useWatch(['farm']);
  const formInstance = useFormInstance();
  const [openEdit, setOpenEdit] = useState(false);
  const [hasSearchValue, setHasSearchValue] = useState(false);
  const { t } = useTranslation();
  const { currentOrganizationId } = useOrganization();
  const { getCurrencyByFarm } = useFarm();
  const metadata = useMemo(
    () => ({
      organizationId: currentOrganizationId,
    }),
    [currentOrganizationId],
  );

  const { selectProps: farmSelectProps, query } = useSelect({
    optionLabel: 'name',
    optionValue: 'id',
    resource: 'farm',
    onSearch: (value) => {
      setHasSearchValue(!!value);

      return [
        {
          field: 'name',
          operator: 'eq',
          value: value,
        },
      ];
    },
    pagination: {
      pageSize: 50,
    },
    meta: metadata,
    queryOptions: {
      enabled: !!metadata.organizationId,
    },
  });

  const handleChangeOpenEdit = () => {
    setOpenEdit(!openEdit);
  };

  useEffect(() => {
    if (!hasSearchValue && query.data?.data?.length === 1) {
      formInstance.setFieldsValue({ farm: query.data.data[0].name });
    }
  }, [formInstance, hasSearchValue, query.data]);

  useEffect(() => {
    if (farm) {
      const currency = getCurrencyByFarm(farm);
      formInstance.setFieldsValue({ currency });
    }
  }, [farm, getCurrencyByFarm, formInstance]);

  return (
    <Styled.Box isSuccess={!!farm}>
      <Styled.FormItem
        name={['farm']}
        rules={[
          {
            required: true,
          },
        ]}
      >
        {openEdit ? (
          <SelectWithButton
            {...farmSelectProps}
            style={{ width: 360 }}
            defaultVisible
            data-testid={PurchaseOrderFarmTestId.PURCHASE_ORDER_FARM_SELECT}
          />
        ) : (
          <Styled.BoxWarehouse
            isSuccess={!!farm}
            onClick={handleChangeOpenEdit}
          >
            {farm ? (
              <>
                <CheckIcon />
                {farm}
              </>
            ) : (
              <>
                <HelpIcon />
                <Typography.Text>
                  {t('electronic_invoice.purchase_order.farm')}
                  <Typography.Text type="secondary">
                    {` ${address}`}
                  </Typography.Text>
                </Typography.Text>
              </>
            )}
          </Styled.BoxWarehouse>
        )}
      </Styled.FormItem>
      <Styled.EditButton
        data-testid={PurchaseOrderFarmTestId.PURCHASE_ORDER_FARM_BUTTON}
        onClick={handleChangeOpenEdit}
      >
        {openEdit ? <CheckIcon /> : <EditIcon />}
      </Styled.EditButton>
    </Styled.Box>
  );
};

export { PurchaseOrderFarm };
