import styled from 'styled-components';

const Container = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  height: calc(100vh - 56px);
  overflow: auto;

  .cw-elements-form-item {
    margin-top: 0 !important;
  }
`;

export { Container };
