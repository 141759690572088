import { UseDrawerFormReturnType } from '@refinedev/antd';
import { useUpdate } from '@refinedev/core';
import { Popconfirm, Spin, Tooltip } from 'antd';
import { ROUTES } from 'common/constants';
import { ArchiveIcon, ArrowRightIcon, EyeOutlinedIcon } from 'common/icons';
import { useNavigateWithOrgAndFarm, useTracking } from 'common/utils';
import { useTranslation } from 'react-i18next';
import { NeutralColors, RedColors } from 'theme';

import { ElectronicInvoiceEnum } from '../electronic-invoice.enum';
import { ActionsElectronicInvoiceTestId } from '../electronic-invoice-test-id.constants';
import * as Styled from './actions-electronic-invoice.style';

interface ActionsElectronicInvoiceProps {
  id: string;
  status: ElectronicInvoiceEnum;
  showAll: boolean;
  blockImport: boolean;
  drawerForm: UseDrawerFormReturnType;
  purchaseOrderId?: string;
  refetch?: () => void;
}

const ActionsElectronicInvoice = ({
  id,
  status,
  showAll,
  blockImport,
  drawerForm,
  purchaseOrderId,
  refetch,
}: ActionsElectronicInvoiceProps) => {
  const { track } = useTracking();
  const { t } = useTranslation();
  const navigate = useNavigateWithOrgAndFarm();
  const { mutate, isLoading: isLoadingArchived } = useUpdate();

  const handleGoToElectronicInvoiceLink = () => {
    navigate(ROUTES.ELECTRONIC_INVOICE_LINK, undefined, undefined, undefined, {
      idsElectronicInvoice: [id],
      purchaseOrderId,
    });
  };

  const handleArchiveElectronicInvoice = () => {
    track('Electronic Invoice - Archive');
    mutate({
      resource: 'electronic-invoice',
      values: {
        status: ElectronicInvoiceEnum.ARCHIVED,
      },
      id,
      successNotification: () => {
        if (refetch) {
          refetch();
        }
        return {
          type: 'success',
          description: t('electronic_invoice.to_file.description') ?? '',
          message: t('electronic_invoice.to_file.message') ?? '',
        };
      },
    });
  };

  const handleViewDetails = () => {
    track('Electronic Invoice - View Details');
    drawerForm.show(id);
  };

  return (
    <Styled.Container>
      {showAll && (
        <Tooltip title={t('buttons.to_file')}>
          <Popconfirm
            title={t('electronic_invoice.buttons.confirmation_title')}
            description={t(
              'electronic_invoice.buttons.confirmation_description',
            )}
            onConfirm={handleArchiveElectronicInvoice}
            okText={t('buttons.to_file')}
            cancelText={t('buttons.cancel')}
            okButtonProps={{ style: { backgroundColor: RedColors[60] } }}
          >
            <Styled.Button
              data-testid={
                ActionsElectronicInvoiceTestId.ACTIONS_ARCHIVE_BUTTON
              }
            >
              {isLoadingArchived ? <Spin size="small" /> : <ArchiveIcon />}
            </Styled.Button>
          </Popconfirm>
        </Tooltip>
      )}
      <Tooltip title={t('buttons.view')}>
        <Styled.Button
          data-testid={ActionsElectronicInvoiceTestId.ACTIONS_VIEW_BUTTON}
          onClick={handleViewDetails}
        >
          <EyeOutlinedIcon />
        </Styled.Button>
      </Tooltip>
      {showAll && (
        <Tooltip title={t('buttons.import')}>
          <Styled.Button
            type="primary"
            data-testid={ActionsElectronicInvoiceTestId.ACTIONS_IMPORT_BUTTON}
            onClick={handleGoToElectronicInvoiceLink}
            disabled={status === ElectronicInvoiceEnum.FAILED || blockImport}
          >
            <ArrowRightIcon
              style={{
                fill:
                  status === ElectronicInvoiceEnum.FAILED || blockImport
                    ? NeutralColors[40]
                    : NeutralColors[0],
              }}
            />
          </Styled.Button>
        </Tooltip>
      )}
    </Styled.Container>
  );
};

export { ActionsElectronicInvoice };
