import { useSelect } from '@refinedev/antd';
import { Space } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { SelectWithButton } from 'common/components';
import { CheckIcon, EditIcon, HelpIcon } from 'common/icons';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReceivedPaidBankTestId } from 'src/orders-and-payments/electronic-invoice/electronic-invoice-test-id.constants';
import { useOrganization } from 'src/organization';
import { BankAccountModel } from 'src/resources/bank-account/bank-account.model';

import * as Styled from './received-paid-bank.style';

const ReceivedPaidBank = () => {
  const paymentAccount = useWatch(['paymentAccount']);
  const farm = useWatch(['farm']);
  const [openEdit, setOpenEdit] = useState(false);
  const { t } = useTranslation();
  const { currentOrganizationId } = useOrganization();
  const formInstance = useFormInstance();
  const metadata = useMemo(
    () => ({
      organizationId: currentOrganizationId,
    }),
    [currentOrganizationId],
  );

  const { selectProps: accountSelectProps, query: accountQueryResult } =
    useSelect<BankAccountModel>({
      resource: 'account',
      optionLabel: 'name',
      optionValue: 'id',
      onSearch: (value) => [
        {
          field: 'name',
          operator: 'eq',
          value: value,
        },
      ],
      filters: [
        {
          field: 'farm',
          operator: 'eq',
          value: farm,
        },
        {
          field: 'accountType',
          operator: 'eq',
          value: ['Bank', 'Cash'],
        },
        {
          field: 'reportType',
          operator: 'eq',
          value: 'Balance Sheet',
        },
        {
          field: 'isGroup',
          operator: 'eq',
          value: 0,
        },
        {
          field: 'isDisabled',
          operator: 'eq',
          value: 0,
        },
      ],
      queryOptions: {
        enabled: !!metadata.organizationId && !!farm,
      },
    });

  useEffect(() => {
    if (accountQueryResult?.data?.data) {
      const account = accountQueryResult.data.data.find(
        (bankAccount) => bankAccount.id === paymentAccount,
      );
      formInstance?.setFieldValue('paymentAccountType', account?.type ?? '');
    }
  }, [formInstance?.setFieldValue, accountQueryResult?.data, paymentAccount]);

  const handleChangeOpenEdit = () => {
    setOpenEdit(!openEdit);
  };

  return (
    <Styled.WarehouseBox>
      {t('electronic_invoice.received_paid.question_bank')}
      <Styled.FormItem name={['paymentAccountType']} hidden />
      <Space>
        <Styled.FormItem
          name={['paymentAccount']}
          rules={[
            {
              required: true,
            },
          ]}
        >
          {openEdit ? (
            <SelectWithButton
              {...accountSelectProps}
              style={{ width: 360 }}
              defaultVisible
              data-testid={ReceivedPaidBankTestId.RECEIVED_PAID_BANK_SELECT}
            />
          ) : (
            <Styled.BoxWarehouse
              isSuccess={!!paymentAccount}
              onClick={handleChangeOpenEdit}
            >
              {paymentAccount ? (
                <>
                  <CheckIcon />
                  {paymentAccount}
                </>
              ) : (
                <>
                  <HelpIcon />
                  {t('electronic_invoice.received_paid.click_bank')}
                </>
              )}
            </Styled.BoxWarehouse>
          )}
        </Styled.FormItem>
        <Styled.EditButton
          data-testid={ReceivedPaidBankTestId.RECEIVED_PAID_BANK_BUTTON}
          onClick={handleChangeOpenEdit}
        >
          {openEdit ? <CheckIcon /> : <EditIcon />}
        </Styled.EditButton>
      </Space>
    </Styled.WarehouseBox>
  );
};

export { ReceivedPaidBank };
