import { Form, Skeleton } from 'antd';
import { CurrencyValue } from 'common/components';
import { BackIcon, ElectronicInvoiceIcon } from 'common/icons';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ItemDrawer } from 'src/resources/item';

import { ElectronicInvoiceStepEnum } from '../electronic-invoice.enum';
import { ElectronicInvoiceConfirm } from './electronic-invoice-confirm/electronic-invoice-confirm.component';
import { ElectronicInvoiceFinish } from './electronic-invoice-finish/electronic-invoice-finish.component';
import { ElectronicInvoiceItems } from './electronic-invoice-items/electronic-invoice-items.component';
import { useElectronicInvoiceLink } from './electronic-invoice-link.hook';
import * as Styled from './electronic-invoice-link.style';
import { ElectronicInvoiceLoading } from './electronic-invoice-loading/electronic-invoice-loading.component';
import { ElectronicInvoicePurchaseOrder } from './electronic-invoice-purchase-order/electronic-invoice-purchase-order.component';
import { ElectronicInvoiceReceivedPaid } from './electronic-invoice-received-paid/electronic-invoice-received-paid.component';

const TOTAL_STEPS = 4;

const ElectronicInvoiceLinkContainer = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    currentStep,
    createUseDrawerForm,
    initialValues,
    totalValue,
    amountItems,
    isLoading,
    purchaseOrder,
    electronicInvoices,
    setCurrentStep,
    onFinish,
    setResourceCreate,
    handleOpenCreateItem,
    setIsLoading,
  } = useElectronicInvoiceLink();

  return (
    <Styled.Container>
      <ItemDrawer
        useDrawerForm={createUseDrawerForm}
        setResourceCreate={setResourceCreate}
      />
      <Styled.Header>
        <Styled.Flex>
          <Styled.BackButton onClick={() => navigate(-1)} type="text">
            <BackIcon />
          </Styled.BackButton>
          <Styled.Title level={4}>
            {t('electronic_invoice.link.title')}
          </Styled.Title>
        </Styled.Flex>
        <Styled.Flex>
          {amountItems &&
          totalValue &&
          currentStep < ElectronicInvoiceStepEnum.FINISH ? (
            <>
              <Styled.ContainerAmount>
                <Styled.BoxAmount>{`${amountItems}x`}</Styled.BoxAmount>
                <ElectronicInvoiceIcon />
              </Styled.ContainerAmount>
              <Styled.TotalText>
                <span>{t('electronic_invoice.details.total')}</span>
                <CurrencyValue value={totalValue ?? 0} />
              </Styled.TotalText>
              <Styled.Progress
                percent={((currentStep + 1) / TOTAL_STEPS) * 100}
                showInfo={false}
              />
              <Styled.StepText>
                {t('electronic_invoice.link.steps', {
                  currentStep: currentStep + 1,
                  totalSteps: TOTAL_STEPS,
                })}
              </Styled.StepText>
            </>
          ) : (
            <Skeleton active />
          )}
        </Styled.Flex>
      </Styled.Header>
      {currentStep < ElectronicInvoiceStepEnum.FINISH ? (
        <Styled.BodyElectronicInvoice>
          {initialValues && currentStep < ElectronicInvoiceStepEnum.FINISH && (
            <Form initialValues={initialValues} onFinish={onFinish} preserve>
              {amountItems &&
                totalValue &&
                currentStep >=
                  ElectronicInvoiceStepEnum.SELECT_PURCHASE_ORDER && (
                  <ElectronicInvoicePurchaseOrder
                    currentStep={currentStep}
                    setCurrentStep={setCurrentStep}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    purchaseOrder={purchaseOrder}
                    eletronicInvoices={electronicInvoices}
                  />
                )}
              {electronicInvoices &&
                electronicInvoices?.length > 0 &&
                currentStep >= ElectronicInvoiceStepEnum.LINK_ITEMS && (
                  <ElectronicInvoiceItems
                    openCreateItem={handleOpenCreateItem}
                    purchaseOrder={purchaseOrder}
                    currentStep={currentStep}
                    isLoading={isLoading}
                    setCurrentStep={setCurrentStep}
                    setIsLoading={setIsLoading}
                  />
                )}
              {currentStep >= ElectronicInvoiceStepEnum.PAID_RECEIVED && (
                <ElectronicInvoiceReceivedPaid
                  currentStep={currentStep}
                  isLoading={isLoading}
                  setCurrentStep={setCurrentStep}
                  setIsLoading={setIsLoading}
                />
              )}
              {currentStep >= ElectronicInvoiceStepEnum.CONFIRM && (
                <ElectronicInvoiceConfirm isLoading={isLoading} />
              )}
            </Form>
          )}
          {isLoading && <ElectronicInvoiceLoading />}
        </Styled.BodyElectronicInvoice>
      ) : (
        <ElectronicInvoiceFinish />
      )}
    </Styled.Container>
  );
};

export { ElectronicInvoiceLinkContainer };
