import { ButtonProps, Select, SelectProps } from 'antd';
import {
  JSXElementConstructor,
  MouseEventHandler,
  ReactElement,
  useState,
} from 'react';

import * as Styled from './select-with-button.style';

interface SelectWithButtonProps extends SelectProps {
  buttonProps?: ButtonProps;
  defaultVisible?: boolean;
  disableVisibleOnClick?: boolean;
}

const SelectWithButton = ({
  buttonProps,
  defaultVisible = false,
  disableVisibleOnClick = true,
  ...props
}: SelectWithButtonProps) => {
  const [visible, setVisible] = useState(defaultVisible);

  const handleButtonClick: MouseEventHandler<HTMLAnchorElement> &
    MouseEventHandler<HTMLButtonElement> = (event) => {
    if (disableVisibleOnClick) {
      setVisible(false);
    }
    buttonProps?.onClick?.(event as any);
  };

  const renderDropdown = (
    menuNode: ReactElement<any, string | JSXElementConstructor<any>>,
  ) => (
    <Styled.ContainerSelect hasButton={!!buttonProps}>
      {menuNode}
      {visible && buttonProps && (
        <Styled.ContainerButton>
          <Styled.Button
            type="primary"
            {...buttonProps}
            onClick={handleButtonClick}
          />
        </Styled.ContainerButton>
      )}
    </Styled.ContainerSelect>
  );

  return (
    <Select
      {...props}
      open={visible}
      onDropdownVisibleChange={(open) => setVisible(open)}
      dropdownRender={renderDropdown}
    />
  );
};

export { SelectWithButton };
