import { Button, Skeleton, Space, Typography } from 'antd';
import { getCurrencyValue } from 'common/utils/currency.util';
import { useTranslation } from 'react-i18next';
import { useFarm } from 'src/organization';

import { StockBalanceTestIds } from './stock-balance.constants';
import { useStockBalance } from './stock-balance.hook';
import * as Styled from './stock-balance.style';

interface StockBalanceProps {
  farmId?: string;
  warehouseId?: string;
}

const StockBalance = ({ farmId, warehouseId }: StockBalanceProps) => {
  const { t, i18n } = useTranslation();
  const { getCurrencyByFarm } = useFarm();

  const { selectedBalance, isFetching, visible, setVisible } = useStockBalance(
    farmId,
    warehouseId,
  );

  return (
    <Styled.Wrapper>
      <Space align="center" size="large">
        <Space size="small">
          <Styled.FunctionsCustomIcon />
          <Typography.Text
            data-testid={StockBalanceTestIds.STOCK_BALANCE_TITLE}
          >
            {t('stock.stock_balance.title')}
          </Typography.Text>
          {visible && !isFetching ? (
            <Typography.Text
              strong
              data-testid={StockBalanceTestIds.STOCK_BALANCE_VALUE}
            >
              {getCurrencyValue(
                selectedBalance ?? 0,
                getCurrencyByFarm(farmId),
                i18n.language,
              )}
            </Typography.Text>
          ) : (
            <Skeleton.Input style={{ width: 50 }} size="small" />
          )}
        </Space>
        <Button
          type="text"
          size="small"
          onClick={() => setVisible((value) => !value)}
          icon={
            visible ? (
              <Styled.EyeOutlinedCustomIcon />
            ) : (
              <Styled.EyeOffOutlinedCustomIcon />
            )
          }
          data-testid={StockBalanceTestIds.STOCK_BALANCE_VISIBLE_BUTTON}
        />
      </Space>
    </Styled.Wrapper>
  );
};

export { StockBalance as default, StockBalance };
