import { Typography } from 'antd';
import { ElectronicInvoiceIcon } from 'common/icons';
import styled from 'styled-components';
import { NeutralColors } from 'theme';

const Title = styled(Typography.Title)`
  span {
    font-weight: 300;
    color: ${NeutralColors[70]};
  }
`;

const Icon = styled(ElectronicInvoiceIcon)`
  width: 16px;
  fill: ${NeutralColors[70]};
`;

export { Icon, Title };
