import { useSelect } from '@refinedev/antd';
import { DrawerProps, FormProps, Grid } from 'antd';
import { useTracking } from 'common/utils';
import { useEffect, useMemo, useState } from 'react';
import { useOrganization } from 'src/organization';

import { WarehouseCreateModel } from './warehouse-create.model';

const useWarehouseCreate = (formProps: FormProps, drawerProps: DrawerProps) => {
  const { track } = useTracking();
  const breakpoint = Grid.useBreakpoint();
  const { currentOrganizationId, isLoading: isOrganizationLoading } =
    useOrganization();
  const [disableSave, setDisableSave] = useState<boolean>(true);
  const [data, setData] = useState<WarehouseCreateModel | null>(null);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);

  const loadFarms = useMemo(
    () => !!currentOrganizationId && !isOrganizationLoading,
    [currentOrganizationId, isOrganizationLoading],
  );

  const metadata = useMemo(
    () => ({
      organizationId: currentOrganizationId,
    }),
    [currentOrganizationId],
  );

  const drawerTitle = useMemo(
    () => (isEditMode ? 'stock.edit.title' : 'stock.create.title'),
    [isEditMode],
  );

  const { selectProps: farmSelectProps } = useSelect({
    resource: 'farm',
    onSearch: (value) => [
      {
        field: 'name',
        operator: 'eq',
        value: value,
      },
    ],
    meta: metadata,
    queryOptions: {
      enabled: loadFarms,
    },
    pagination: {
      pageSize: 50,
    },
  });

  const drawerWidth = useMemo(
    () => (breakpoint.sm ? '400px' : '100%'),
    [breakpoint.sm],
  );

  useEffect(() => {
    if (!drawerProps.open) {
      setDisableSave(true);
      formProps.form?.resetFields();
    }
  }, [drawerProps.open, formProps.form]);

  useEffect(() => {
    if (!data) {
      return;
    }

    const shouldDisableSave = !data.farm || !data.name;

    setDisableSave(shouldDisableSave);
  }, [data]);

  const handleFormValuesChange = (
    _: string,
    allValues: WarehouseCreateModel,
  ) => {
    setData(allValues);
  };

  const handleOnFinish = (values: WarehouseCreateModel) => {
    track('End Warehouse Creation');

    if (formProps.onFinish) {
      return formProps.onFinish(data);
    }
  };

  return {
    disableSave,
    drawerWidth,
    drawerTitle,
    farmSelectProps,
    isEditMode,
    setIsEditMode,
    handleFormValuesChange,
    handleOnFinish,
  };
};

export { useWarehouseCreate as default, useWarehouseCreate };
