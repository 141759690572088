import {
  EyeOffOutlinedIcon,
  EyeOutlinedIcon,
  FunctionsIcon,
} from 'common/icons';
import styled from 'styled-components';
import { NeutralColors } from 'theme';

const Wrapper = styled.div`
  border: 1px solid ${NeutralColors[20]};
  padding: 4px 8px 4px 16px;
  border-radius: 12px;

  :hover {
    background-color: ${NeutralColors[10]};
  }
`;

const FunctionsCustomIcon = styled(FunctionsIcon)`
  color: ${NeutralColors[60]};
  width: 18px;
  height: 18px;
  display: block;
`;

const EyeOffOutlinedCustomIcon = styled(EyeOffOutlinedIcon)`
  color: ${NeutralColors[60]};
  width: 18px;
  height: 18px;
  display: block;
`;

const EyeOutlinedCustomIcon = styled(EyeOutlinedIcon)`
  color: ${NeutralColors[60]};
  width: 18px;
  height: 18px;
  display: block;
`;

export {
  EyeOffOutlinedCustomIcon,
  EyeOutlinedCustomIcon,
  FunctionsCustomIcon,
  Wrapper,
};
