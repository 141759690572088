export const ActionsElectronicInvoiceTestId = {
  ACTIONS_ARCHIVE_BUTTON: 'actions-archive-button',
  ACTIONS_VIEW_BUTTON: 'actions-view-button',
  ACTIONS_IMPORT_BUTTON: 'actions-import-button',
};

export const ElectronicInvoiceLoadingTestId = {
  ELECTRONIC_INVOICE_LOADING_CONTAINER: 'electronic-invoice-loading-container',
};

export const ItemLinkSelectTestId = {
  ITEM_LINK_SELECT_BUTTON: 'item-link-select-button',
};

export const ItemLinkTestId = {
  ITEM_LINK_BUTTON: 'item-link-button',
};

export const ReceivedPaidWarehouseTestId = {
  RECEIVED_PAID_WAREHOUSE_BUTTON: 'received-paid-warehouse-button',
  RECEIVED_PAID_WAREHOUSE_SELECT: 'received-paid-warehouse-select',
};

export const ReceivedPaidBankTestId = {
  RECEIVED_PAID_BANK_BUTTON: 'received-paid-bank-button',
  RECEIVED_PAID_BANK_SELECT: 'received-paid-bank-select',
};

export const PurchaseOrderSupplierTestId = {
  PURCHASE_ORDER_SUPPLIER_BUTTON: 'purchase-order-supplier-button',
  PURCHASE_ORDER_SUPPLIER_SELECT: 'purchase-order-supplier-select',
};

export const PurchaseOrderNumberTestId = {
  PURCHASE_ORDER_NUMBER_BUTTON: 'purchase-order-number-button',
  PURCHASE_ORDER_NUMBER_INPUT: 'purchase-order-number-input',
};

export const PurchaseOrderFarmTestId = {
  PURCHASE_ORDER_FARM_BUTTON: 'purchase-order-farm-button',
  PURCHASE_ORDER_FARM_SELECT: 'purchase-order-farm-select',
};

export const PurchaseOrderDateTestId = {
  PURCHASE_ORDER_DATE_BUTTON: 'purchase-order-date-button',
  PURCHASE_ORDER_DATE_PICKER: 'purchase-order-date-picker',
};
