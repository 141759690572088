import { Tag } from 'antd';
import { useTranslation } from 'react-i18next';

import {
  statusColor,
  StatusEnum,
  StatusTranslationEnum,
} from './status-column.enum';

interface StatusColumnProps {
  enabled: boolean;
}

export const StatusColumn = ({ enabled }: StatusColumnProps) => {
  const { t } = useTranslation();
  return (
    <Tag
      color={statusColor[enabled ? StatusEnum.Enabled : StatusEnum.Disabled]}
    >
      {t(
        `supplier.status.${
          StatusTranslationEnum[
            enabled ? StatusEnum.Enabled : StatusEnum.Disabled
          ]
        }`,
      )}
    </Tag>
  );
};
