import { Button } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import FormItem from 'antd/es/form/FormItem';
import { ShoppingCartSuccessColoredIcon } from 'common/icons';
import dayjs from 'dayjs';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ElectronicInvoiceStepEnum } from 'src/orders-and-payments/electronic-invoice/electronic-invoice.enum';
import { ElectronicInvoiceModel } from 'src/orders-and-payments/electronic-invoice/electronic-invoice.model';
import { PurchaseDetailModel } from 'src/orders-and-payments/purchase';

import * as Styled from './electronic-invoice-purchase-order.style';
import { PurchaseOrderDate } from './purchase-order-due-date/purchase-order-due-date.component';
import { PurchaseOrderFarm } from './purchase-order-farm/purchase-order-farm.component';
import { PurchaseOrderNumber } from './purchase-order-number/purchase-order-number.component';
import { PurchaseOrderSupplier } from './purchase-order-supplier/purchase-order-supplier.component';

interface ElectronicInvoicePurchaseOrderProps {
  isLoading: boolean;
  setIsLoading: (e: boolean) => void;
  currentStep: ElectronicInvoiceStepEnum;
  setCurrentStep: (e: ElectronicInvoiceStepEnum) => void;
  eletronicInvoices?: ElectronicInvoiceModel[];
  purchaseOrder?: PurchaseDetailModel;
}

const ElectronicInvoicePurchaseOrder = ({
  currentStep,
  setCurrentStep,
  setIsLoading,
  isLoading,
  purchaseOrder,
  eletronicInvoices,
}: ElectronicInvoicePurchaseOrderProps) => {
  const { t } = useTranslation();

  const farm = useWatch(['farm']);
  const dueDate = useWatch(['dueDate']);
  const supplier = useWatch(['supplier']);

  const address = useMemo(() => {
    if (eletronicInvoices?.[0]) {
      return `${
        eletronicInvoices[0].targetAddrStreet
      }. ${eletronicInvoices[0].targetAddrZipcode?.substring(
        0,
        5,
      )}-${eletronicInvoices[0].targetAddrZipcode?.substring(5, 8)}. ${
        eletronicInvoices[0].targetAddrCity
      }`;
    }
  }, [eletronicInvoices]);

  const handleGoToStep = useCallback(() => {
    if (purchaseOrder) {
      return;
    }
    setCurrentStep(ElectronicInvoiceStepEnum.SELECT_PURCHASE_ORDER);
  }, [purchaseOrder, setCurrentStep]);

  const handleGoToNextStep = () => {
    setIsLoading(true);

    setTimeout(() => {
      setIsLoading(false);
      setCurrentStep(ElectronicInvoiceStepEnum.LINK_ITEMS);
    }, 1000);
  };

  const isCurrentStep = useMemo(
    () =>
      currentStep === ElectronicInvoiceStepEnum.SELECT_PURCHASE_ORDER &&
      !isLoading,
    [currentStep, isLoading],
  );

  return (
    <>
      <Styled.ContainerQuestion isQuestion={isCurrentStep}>
        <ShoppingCartSuccessColoredIcon />
        <Styled.BoxQuestion isQuestion={isCurrentStep} onClick={handleGoToStep}>
          {isCurrentStep ? (
            <Styled.TitleQuestion level={4}>
              {t('electronic_invoice.purchase_order.title_question')}
            </Styled.TitleQuestion>
          ) : (
            <Styled.BoxSuccess>
              <Styled.Text>
                {t('electronic_invoice.purchase_order.relation_purchase')}
                <span>
                  {` ${dayjs(dueDate).format('DD/MM/YYYY')} ${t(
                    'common.of',
                  )} ${supplier}`}
                </span>
              </Styled.Text>
            </Styled.BoxSuccess>
          )}
        </Styled.BoxQuestion>
      </Styled.ContainerQuestion>
      {isCurrentStep && (
        <div>
          <Styled.ContainerOptions>
            <PurchaseOrderFarm address={address} />
            <PurchaseOrderSupplier
              eletronicInvoiceSupplier={
                eletronicInvoices?.[0].sourceFantasyName
              }
            />
            <PurchaseOrderDate />
            <PurchaseOrderNumber />
          </Styled.ContainerOptions>
          {farm && dueDate && supplier && (
            <Styled.FormItemSubmit>
              <Button type="primary" onClick={handleGoToNextStep}>
                {t('buttons.continue')}
              </Button>
            </Styled.FormItemSubmit>
          )}
        </div>
      )}
      <FormItem name={['supplier']} hidden />
      <FormItem name={['dueDate']} hidden />
      <FormItem name={['farm']} hidden />
      <FormItem name={['purchaseNumber']} hidden />
      <FormItem name={['currency']} hidden />
      <FormItem name={['sourceCnpjCpf']} hidden />
    </>
  );
};

export { ElectronicInvoicePurchaseOrder };
