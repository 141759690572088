import { useOne } from '@refinedev/core';
import { Divider } from 'antd';
import {
  CalendarClockIcon,
  ClockIcon,
  MoneyIcon,
  PriceChangeIcon,
  PurchaseIcon,
} from 'common/icons';
import {
  getCurrencyValue,
  getValueWithCurrency,
} from 'common/utils/currency.util';
import { useTranslation } from 'react-i18next';

import { ContainerIconValue } from '../../container-icon-value/container-icon-value.component';
import { OtherCurrencyModel, PurchaseFormModel } from '../../purchase.model';
import * as Styled from './box-payed.style';

interface BoxPayedProps {
  purchaseOrder: PurchaseFormModel;
  show: boolean;
}

const BoxPayed = ({ purchaseOrder, show }: BoxPayedProps) => {
  const { t, i18n } = useTranslation();

  const { data } = useOne<OtherCurrencyModel>({
    resource: 'other-currency',
    id: purchaseOrder.typeOtherCurrency,
    queryOptions: {
      enabled: !!purchaseOrder.typeOtherCurrency,
    },
  });

  if (!purchaseOrder || !data?.data || !show) {
    return null;
  }

  const valueOtherCurrency =
    (purchaseOrder.valueOtherCurrency ?? 0) -
    (purchaseOrder.paidAmountOtherCurrency ?? 0);

  return (
    <Styled.BoxPayed>
      {purchaseOrder.isOtherCurrency && (
        <ContainerIconValue
          Icon={<PurchaseIcon />}
          title={t('purchase_create.payed.total_traded')}
          value={getValueWithCurrency(
            purchaseOrder.valueOtherCurrency ?? 0,
            data?.data.symbol,
            i18n.language,
          )}
        />
      )}
      <Styled.Flex>
        <ContainerIconValue
          Icon={<ClockIcon />}
          title={t('purchase_create.payed.total_outstanding')}
          value={
            purchaseOrder.isOtherCurrency
              ? getValueWithCurrency(
                  valueOtherCurrency,
                  data?.data.symbol,
                  i18n.language,
                )
              : getCurrencyValue(
                  (purchaseOrder.grandTotal ?? 0) -
                    (purchaseOrder.paidAmount ?? 0),
                  purchaseOrder.currency,
                  i18n.language,
                )
          }
          complement={
            purchaseOrder.isOtherCurrency
              ? getCurrencyValue(
                  (purchaseOrder.grandTotalWithDiff ?? 0) -
                    (purchaseOrder.paidAmount ?? 0),
                  purchaseOrder.currency,
                  i18n.language,
                )
              : undefined
          }
        />
        <ContainerIconValue
          Icon={<MoneyIcon />}
          title={t('purchase_create.payed.total_payed')}
          value={
            purchaseOrder.isOtherCurrency
              ? getValueWithCurrency(
                  purchaseOrder.paidAmountOtherCurrency ?? 0,
                  data?.data.symbol,
                  i18n.language,
                )
              : getCurrencyValue(
                  purchaseOrder.paidAmount ?? 0,
                  purchaseOrder.currency,
                  i18n.language,
                )
          }
          complement={
            purchaseOrder.isOtherCurrency
              ? getCurrencyValue(
                  purchaseOrder.paidAmount ?? 0,
                  purchaseOrder.currency,
                  i18n.language,
                )
              : undefined
          }
        />
      </Styled.Flex>
      <Divider style={{ margin: 0 }} />
      {purchaseOrder.isOtherCurrency && (
        <>
          <Styled.Flex>
            <ContainerIconValue
              Icon={<PriceChangeIcon />}
              title={t('purchase_create.payed.initial_quote')}
              value={`${getCurrencyValue(
                purchaseOrder.initialExchangeRateOtherCurrency ?? 0,
                purchaseOrder.currency,
                i18n.language,
              )} = ${data?.data.symbol} 1,00`}
              isBlue={false}
            />
            {valueOtherCurrency <= 0 && (
              <ContainerIconValue
                Icon={<PriceChangeIcon />}
                title={t('purchase_create.payed.final_quote')}
                value={`${getCurrencyValue(
                  (purchaseOrder.grandTotalWithDiff ?? 0) /
                    (purchaseOrder.valueOtherCurrency ?? 0),
                  purchaseOrder.currency,
                  i18n.language,
                )} = ${data?.data.symbol} 1,00`}
                isBlue={false}
              />
            )}
          </Styled.Flex>
          <Divider style={{ margin: 0 }} />
        </>
      )}
      <Styled.Flex>
        <ContainerIconValue
          Icon={<CalendarClockIcon />}
          title={t('purchase_create.payed.due_date')}
          value={purchaseOrder.dueDate?.format('DD/MM/YYYY')}
          isBlue={false}
        />
      </Styled.Flex>
    </Styled.BoxPayed>
  );
};

export { BoxPayed };
