import { ElectronicInvoiceGeneric } from 'src/orders-and-payments/electronic-invoice/electronic-invoice.model';

import * as Styled from './electronic-invoice-name-column.style';

interface ElectronicInvoiceNameColumnProps {
  electronicInvoiceList: ElectronicInvoiceGeneric;
}

const ElectronicInvoiceNameColumn = ({
  electronicInvoiceList,
}: ElectronicInvoiceNameColumnProps) => {
  return (
    <Styled.Container>
      <Styled.BoxAmount>
        {electronicInvoiceList.qtyElectronicInvoice}
      </Styled.BoxAmount>
      {electronicInvoiceList.fantasyName}
    </Styled.Container>
  );
};

export { ElectronicInvoiceNameColumn };
