import { DatePicker } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import { CheckIcon, EditIcon, HelpIcon } from 'common/icons';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PurchaseOrderDateTestId } from 'src/orders-and-payments/electronic-invoice/electronic-invoice-test-id.constants';

import * as Styled from './purchase-order-due-date.style';

const PurchaseOrderDate = () => {
  const dueDate = useWatch(['dueDate']);
  const [openEdit, setOpenEdit] = useState(false);
  const { t } = useTranslation();

  const handleChangeOpenEdit = () => {
    setOpenEdit(!openEdit);
  };

  return (
    <Styled.Box isSuccess={!!dueDate}>
      <Styled.FormItem
        name={['dueDate']}
        rules={[
          {
            required: true,
          },
        ]}
      >
        {openEdit ? (
          <DatePicker
            data-testid={PurchaseOrderDateTestId.PURCHASE_ORDER_DATE_PICKER}
          />
        ) : (
          <Styled.BoxWarehouse
            isSuccess={!!dueDate}
            onClick={handleChangeOpenEdit}
          >
            {dueDate ? (
              <>
                <CheckIcon />
                {`${t('electronic_invoice.purchase_order.due_date')}: ${dayjs(
                  dueDate,
                ).format('D MMM YYYY')}`}
              </>
            ) : (
              <>
                <HelpIcon />
                {t('electronic_invoice.purchase_order.due_date_question')}
              </>
            )}
          </Styled.BoxWarehouse>
        )}
      </Styled.FormItem>
      <Styled.EditButton
        data-testid={PurchaseOrderDateTestId.PURCHASE_ORDER_DATE_BUTTON}
        onClick={handleChangeOpenEdit}
      >
        {openEdit ? <CheckIcon /> : <EditIcon />}
      </Styled.EditButton>
    </Styled.Box>
  );
};

export { PurchaseOrderDate };
