import styled from 'styled-components';
import { NeutralColors } from 'theme';

const Container = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
`;

const BoxAmount = styled.div`
  margin-top: 1px;
  border: 1px solid ${NeutralColors[20]};
  border-radius: 14px;
  padding: 1px 6px 0;
  font-size: 12px;
  font-weight: 600;
`;

export { BoxAmount, Container };
