import { Typography } from 'antd';
import styled from 'styled-components';
import { MediaQuery, NeutralColors } from 'theme';

import { BorderedContainer } from '../../dashboard.style';

interface WidgetContainerProps {
  amountYears: number;
}

const TooltipDay = styled.div`
  background-color: ${NeutralColors[0]};
  border-radius: 12px;
  padding: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
`;

const FlexContainer = styled.div`
  overflow-x: auto;
  overflow-y: hidden;
`;

const WidgetContainer = styled.div<WidgetContainerProps>`
  height: ${({ amountYears }) => amountYears * 180}px;
  width: 900px;

  rect {
    rx: 5;
    ry: 5;
  }
`;

const BorderedCalendarContainer = styled(BorderedContainer)`
  flex: 1;

  @media (min-width: ${MediaQuery.screenSizesCss.tablet}) {
    width: calc(66% - 14px);
    min-width: 50%;
  }
`;

const LegendContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const LegendText = styled(Typography.Text)`
  color: ${NeutralColors[70]};
  text-align: right;
`;

export {
  BorderedCalendarContainer,
  FlexContainer,
  LegendContainer,
  LegendText,
  TooltipDay,
  WidgetContainer,
};
