import { useDrawerForm } from '@refinedev/antd';
import { useInvalidate, useOne } from '@refinedev/core';
import { TablePaginationConfig } from 'antd';
import { FarmIcon, SupplierIcon } from 'common/icons';
import {
  ListFiltersModel,
  RefineTableFiltersEnum,
  useRefineTableFilters,
} from 'common/refine-table-filters';
import { useTracking } from 'common/utils';
import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import api from 'src/api.config';
import { PurchaseDetailModel } from 'src/orders-and-payments/purchase/purchase.model';
import { StatusEnum } from 'src/orders-and-payments/purchase/status.enum';
import { useOrganization } from 'src/organization';

import { ElectronicInvoiceEnum } from './electronic-invoice.enum';
import {
  ElectronicInvoiceGeneric,
  ElectronicInvoiceModel,
  InvoiceStatusModel,
} from './electronic-invoice.model';

enum TypeFilter {
  Pending = 'Pending',
  Archived = 'Archived',
  Imported = 'Imported',
}

let lastRefetch = dayjs();
const AWAIT_TIME_REFETCH = 3000;
const MAX_AUTO_REFETCH = 5;
let totalAutoRefetch = 0;

const useElectronicInvoice = (
  referenceType?: string,
  referenceId?: string,
  setIdsElectronicInvoice?: (x: string[]) => void,
  setBlockImport?: (x: boolean) => void,
) => {
  const { t } = useTranslation();
  const { track } = useTracking();
  const [currentFilter, setCurrentFilter] = useState(TypeFilter.Pending);
  const [files, setFiles] = useState<string[]>([]);
  const [finishFiles, setFinishFiles] = useState<string[]>([]);
  const { currentOrganizationId } = useOrganization();
  const invalidate = useInvalidate();

  const metadata = useMemo(
    () => ({
      organizationId: currentOrganizationId,
    }),
    [currentOrganizationId],
  );

  const drawerForm = useDrawerForm({
    action: 'edit',
    resource: 'electronic-invoice',
    redirect: false,
    syncWithLocation: {
      key: 'edit',
    },
  });

  const { data: dataStatus, refetch: refetchDataStatus } = useOne<
    InvoiceStatusModel[]
  >({
    resource: 'electronic-invoice/status-summary',
    id: '',
    meta: {
      ...metadata,
      params: {
        referenceId,
      },
    },
  });

  const statusFilter: string[] = useMemo(() => {
    switch (currentFilter) {
      case TypeFilter.Imported:
        return [ElectronicInvoiceEnum.IMPORTED];
      case TypeFilter.Archived:
        return [ElectronicInvoiceEnum.ARCHIVED];
      case TypeFilter.Pending:
      default:
        return [
          ElectronicInvoiceEnum.FAILED,
          ElectronicInvoiceEnum.TRANSFORMED_WITHOUT_MATCH,
          ElectronicInvoiceEnum.TRANSFORMED_WITH_MATCH,
        ];
    }
  }, [currentFilter]);

  const {
    tableProps: tablePropsProcessing,
    tableQuery: tableQueryResultProcessing,
  } = useRefineTableFilters<ElectronicInvoiceModel>({
    listFilters: [],
    resource: 'electronic-invoice',
    meta: {
      ...metadata,
    },
    queryOptions: {
      enabled: !!referenceId,
    },
    filters: {
      permanent: [
        {
          field: 'status',
          operator: 'eq',
          value: [
            ElectronicInvoiceEnum.PROCESSING,
            ElectronicInvoiceEnum.PROCESSED,
          ],
        },
        {
          field: 'referenceType',
          operator: 'eq',
          value: referenceType,
        },
        {
          field: 'referenceId',
          operator: 'eq',
          value: referenceId,
        },
      ],
    },
  });

  const { tableProps, tableQuery } =
    useRefineTableFilters<ElectronicInvoiceModel>({
      listFilters: [],
      resource: 'electronic-invoice',
      pagination: {
        pageSize: 99999999,
      },
      meta: {
        ...metadata,
      },
      queryOptions: {
        enabled: !!referenceId,
      },
      filters: {
        permanent: [
          {
            field: 'status',
            operator: 'eq',
            value: statusFilter,
          },
          {
            field: 'referenceType',
            operator: 'eq',
            value: referenceType,
          },
          {
            field: 'referenceId',
            operator: 'eq',
            value: referenceId,
          },
        ],
      },
    });

  const refetch = (refetchNow?: boolean) => {
    if (refetchNow) {
      totalAutoRefetch = 0;
    }
    if (
      (dayjs().diff(lastRefetch) > AWAIT_TIME_REFETCH &&
        totalAutoRefetch < MAX_AUTO_REFETCH) ||
      refetchNow
    ) {
      refetchDataStatus();
      tableQueryResultProcessing.refetch();
      if (!referenceId) {
        tableQueryGeneric.refetch();
        tablePropsGeneric.dataSource?.forEach((electronicInvoiceGeneric) => {
          invalidate({
            resource: `electronic-invoice/hall/cnpjCpf/${electronicInvoiceGeneric.cnpjCpf}/invoices`,
            invalidates: ['resourceAll'],
            invalidationOptions: {
              cancelRefetch: true,
            },
          });
        });
      } else {
        tableQuery.refetch();
      }
      lastRefetch = dayjs();
      totalAutoRefetch += 1;
    }
  };

  useEffect(() => {
    setTimeout(refetch, AWAIT_TIME_REFETCH);
  }, [tableProps, tablePropsProcessing]);

  useEffect(() => {
    if (finishFiles.length > 0 && finishFiles.length === files.length) {
      refetch(true);
    }
  }, [finishFiles]);

  useEffect(() => {
    if (setIdsElectronicInvoice && tableProps.dataSource) {
      setIdsElectronicInvoice(
        tableProps.dataSource
          .filter(
            (electronicInvoice) =>
              electronicInvoice.status !== ElectronicInvoiceEnum.FAILED,
          )
          .map((electronicInvoice) => electronicInvoice.id),
      );
    }
  }, [tableProps.dataSource]);

  const sendFile = async (e: any) => {
    files.push(e?.file?.uid);

    setFiles([...files]);
    const formData = new FormData();
    formData.append('file', e.file);
    formData.append('invoiceType', 'NFE');
    if (referenceType && referenceId) {
      formData.append('referenceType', referenceType);
      formData.append('referenceId', referenceId);
    }

    await api
      .post('/v1/electronic-invoice/file', formData, {
        params: { ...metadata },
      })
      .finally(() => {
        finishFiles.push(e?.file?.uid);

        setFinishFiles([...finishFiles]);
      });
  };

  const { data: purchaseOrder } = useOne<PurchaseDetailModel>({
    resource: 'purchase-order',
    id: referenceId,
    queryOptions: {
      enabled: !!referenceId,
    },
  });

  const handleChangeTypeFilter = (filter: TypeFilter) => {
    switch (filter) {
      case TypeFilter.Archived:
        track('Electronic Invoice - View Archived');
        break;
      case TypeFilter.Imported:
        track('Electronic Invoice - View Imported');
        break;
      case TypeFilter.Pending:
      default:
        track('Electronic Invoice - View Pending');
        break;
    }
    setCurrentFilter(filter);
  };

  const blockImport: boolean = useMemo(
    () =>
      !!referenceId &&
      (purchaseOrder?.data.status === StatusEnum.Completed ||
        purchaseOrder?.data.status === StatusEnum.Cancelled ||
        purchaseOrder?.data.status === StatusEnum.ToReceive ||
        purchaseOrder?.data.status === StatusEnum.Draft),
    [referenceId, purchaseOrder],
  );

  useEffect(() => {
    if (setBlockImport) {
      setBlockImport(blockImport);
    }
  }, [blockImport, setBlockImport]);

  const amountPending = useMemo(
    () =>
      dataStatus?.data.reduce(
        (acc: number, invoiceStatus: InvoiceStatusModel) => {
          if (
            invoiceStatus.status === ElectronicInvoiceEnum.FAILED ||
            invoiceStatus.status ===
              ElectronicInvoiceEnum.TRANSFORMED_WITHOUT_MATCH ||
            invoiceStatus.status ===
              ElectronicInvoiceEnum.TRANSFORMED_WITH_MATCH
          ) {
            return invoiceStatus.total + acc;
          }
          return acc;
        },
        0,
      ) ?? 0,
    [dataStatus],
  );

  const amountImported = useMemo(
    () =>
      dataStatus?.data.reduce(
        (acc: number, invoiceStatus: InvoiceStatusModel) => {
          if (invoiceStatus.status === ElectronicInvoiceEnum.IMPORTED) {
            return invoiceStatus.total + acc;
          }
          return acc;
        },
        0,
      ) ?? 0,
    [dataStatus],
  );

  const amountArchived = useMemo(
    () =>
      dataStatus?.data.reduce(
        (acc: number, invoiceStatus: InvoiceStatusModel) => {
          if (invoiceStatus.status === ElectronicInvoiceEnum.ARCHIVED) {
            return invoiceStatus.total + acc;
          }
          return acc;
        },
        0,
      ) ?? 0,
    [dataStatus],
  );

  const listFilters: ListFiltersModel[] = useMemo(
    () => [
      {
        label: t('purchase.farm'),
        field: 'farm',
        type: RefineTableFiltersEnum.SELECT,
        optionValue: 'name',
        resource: 'electronic-invoice/hall/farms',
        show: true,
        icon: <FarmIcon />,
      },
      {
        label: t('common.business.supplier'),
        field: 'cnpjCpf',
        optionLabel: 'fantasyName',
        optionValue: 'cnpjCpf',
        type: RefineTableFiltersEnum.SELECT,
        resource: 'electronic-invoice/hall/suppliers',
        show: true,
        icon: <SupplierIcon />,
      },
    ],
    [t],
  );

  const {
    tableProps: tablePropsGeneric,
    searchFormProps: searchFormPropsGeneric,
    tableQuery: tableQueryGeneric,
  } = useRefineTableFilters<ElectronicInvoiceGeneric>({
    listFilters,
    resource: 'electronic-invoice/hall/all-info',
    filters: {
      permanent: [
        {
          field: 'status',
          operator: 'eq',
          value: statusFilter,
        },
      ],
    },
    pagination: {
      pageSize: 99999999,
    },
    meta: {
      ...metadata,
    },
  });

  return {
    blockImport,
    currentFilter,
    listFilters,
    tablePropsGeneric,
    searchFormPropsGeneric,
    sendFile,
    handleChangeTypeFilter,
    statusFilter,
    tableProps,
    drawerForm,
    amountProcessing:
      (tablePropsProcessing.pagination as TablePaginationConfig)?.total ?? 0,
    refetch: () => refetch(true),
    isFetching: tableQuery.isFetching || tableQueryResultProcessing.isFetching,
    amountPending,
    amountImported,
    amountArchived,
    isSendFetching: files.length !== finishFiles.length,
  };
};

export { TypeFilter, useElectronicInvoice };
