import { useOne } from '@refinedev/core';
import { useMemo, useState } from 'react';
import {
  DashboardRequest,
  StockBalanceReport,
} from 'src/dashboard/dashboard.interface';
import { useOrganization } from 'src/organization';

const useStockBalance = (farmId?: string, warehouseId?: string) => {
  const { currentOrganizationId } = useOrganization();
  const [visible, setVisible] = useState(false);

  const { data, isFetching } = useOne<DashboardRequest<StockBalanceReport>>({
    resource: 'report',
    id: '',
    meta: {
      organizationId: currentOrganizationId,
      params: {
        reportName: 'Warehouse Wise Stock Balance',
        farm: farmId,
      },
    },
    queryOptions: {
      enabled: !!currentOrganizationId && !!farmId,
    },
  });

  const selectedBalance = useMemo(() => {
    if (warehouseId) {
      return data?.data?.result?.find((item) => item.name === warehouseId)
        ?.stockBalance;
    } else {
      return data?.data?.result?.reduce(
        (acc, item) => acc + item.stockBalance,
        0,
      );
    }
  }, [data, warehouseId]);

  return {
    selectedBalance,
    isFetching,
    visible,
    setVisible,
  };
};

export { useStockBalance };
