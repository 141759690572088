import { Tag } from 'antd';
import { useTranslation } from 'react-i18next';

import { InvoiceStatusTestId } from '../invoice-test-id.constant';
import {
  statusColor,
  StatusEnum,
  StatusTranslationEnum,
} from './invoice-status.constant';

interface InvoiceStatusProps {
  status: StatusEnum;
}

export const InvoiceStatus = ({ status }: InvoiceStatusProps) => {
  const { t } = useTranslation();
  return (
    <Tag
      data-testid={InvoiceStatusTestId.INVOICE_STATUS_CONTAINER}
      color={statusColor[status]}
    >
      {t(`invoice.status.${StatusTranslationEnum[status]}`)}
    </Tag>
  );
};
