import { CurrencyValue } from 'common/components';
import { useTranslation } from 'react-i18next';
import { ElectronicInvoiceItemModel } from 'src/orders-and-payments/electronic-invoice/electronic-invoice.model';

import * as Styled from './invoice-items.style';

interface InvoiceItemsProps {
  item: ElectronicInvoiceItemModel;
}

const InvoiceItems = ({ item }: InvoiceItemsProps) => {
  const { t } = useTranslation();

  return (
    <Styled.Container>
      <Styled.ContainerSpaceBetween isBorderBottom>
        <Styled.Text isBold>{item.itemName}</Styled.Text>
        <Styled.Text>{`${item.qty} ${item.uom}`}</Styled.Text>
      </Styled.ContainerSpaceBetween>
      <Styled.ContainerSpaceBetween>
        <Styled.Text>
          <CurrencyValue value={item.valuationRate} />
          <span>{`/ ${item.uom}`}</span>
        </Styled.Text>
        <Styled.Text>
          <span>{t('electronic_invoice.details.total')}</span>
          <CurrencyValue value={item.totalValue} />
        </Styled.Text>
      </Styled.ContainerSpaceBetween>
    </Styled.Container>
  );
};

export { InvoiceItems };
