import { IResourceComponentsProps } from '@refinedev/core';
import { Button, Progress, Space, Table } from 'antd';
import { CurrencyValue, FormattedDate } from 'common/components';
import { usePermissionsBalance } from 'common/hooks/use-permissions-balance.hook';
import { AddIcon, EyeOutlinedIcon } from 'common/icons';
import { RefineTableFilters } from 'common/refine-table-filters';
import { ActionRbacEnum } from 'common/utils/auth-provider.util';
import { FC, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { OrdersAndPaymentsEnum } from '../orders-and-payments.enum';
import { OrdersAndPaymentsTitle } from '../orders-and-payments-title.component';
import { InvoiceFormContainer } from './form';
import { InvoiceModel } from './invoice.model';
import useInvoiceList from './invoice-list.hook';
import * as Styled from './invoice-list.style';
import InvoicePaymentForm from './payment-form/invoice-payment-form.container';
import { InvoiceStatus } from './status/invoice-status.component';
import { StatusEnum } from './status/invoice-status.constant';

const InvoiceListContainer: FC<IResourceComponentsProps> = () => {
  const { t } = useTranslation();
  const { permissions } = usePermissionsBalance();

  const {
    tableProps,
    searchFormProps,
    tableQueryResult,
    listFilters,
    handleOnClickCreateInvoicePayment,
    handleOnClickCreateInvoice,
    handleOnClickEditInvoice,
    createDrawer,
    editDrawer,
    paymentDrawer,
  } = useInvoiceList();

  const onRow = (data: InvoiceModel) => ({
    onClick: (event: MouseEvent<HTMLElement>) => {
      const target = event.target as HTMLElement;
      if (target.tagName !== 'TD') {
        event.stopPropagation();
        return;
      }

      handleOnClickEditInvoice(data.id);
    },
  });

  return (
    <>
      <InvoiceFormContainer drawerForm={createDrawer} />
      <InvoiceFormContainer drawerForm={editDrawer} />
      <InvoicePaymentForm
        drawerForm={paymentDrawer}
        refetchTable={tableQueryResult.refetch}
      />
      <OrdersAndPaymentsTitle
        buttons={
          permissions?.includes(ActionRbacEnum.STOCK_BALANCE_READ) && (
            <Styled.CreateButton
              onClick={handleOnClickCreateInvoice}
              icon={<AddIcon />}
              type="primary"
            >
              {t('buttons.create')}
            </Styled.CreateButton>
          )
        }
        selectedTab={OrdersAndPaymentsEnum.INVOICE}
      />
      <RefineTableFilters
        searchFormProps={searchFormProps}
        initialListFilters={listFilters}
      />
      <Table
        {...tableProps}
        rowKey="id"
        onRow={onRow}
        pagination={{
          ...tableProps.pagination,
          showSizeChanger: true,
          pageSizeOptions: [10, 20, 50, 100],
        }}
      >
        <Table.Column
          dataIndex="id"
          title={t('invoice.table.id')}
          render={(id) => id.replace('ACC-PINV-', '')}
        />
        <Table.Column dataIndex="farm" title={t('invoice.table.farm')} />
        <Table.Column
          dataIndex="supplier"
          title={t('invoice.table.supplier')}
        />
        <Table.Column
          dataIndex="due_date"
          title={t('invoice.table.due_date')}
          render={(_, { dueDate }: InvoiceModel) => (
            <FormattedDate date={dueDate} />
          )}
          sorter
        />
        <Table.Column
          dataIndex="status"
          title={t('invoice.table.status')}
          render={(status) => <InvoiceStatus status={status} />}
          sorter
        />
        <Table.Column
          dataIndex="percentInvoiced"
          title={t('invoice.table.invoiced')}
          render={(_, { percentBilled }: InvoiceModel) => (
            <Progress percent={percentBilled} size="small" />
          )}
        />
        {permissions?.includes(ActionRbacEnum.FINANCIAL_BALANCE_READ) && (
          <Table.Column
            dataIndex="grand_total"
            title={t('invoice.table.total')}
            render={(_, { currency, total }: InvoiceModel) => (
              <CurrencyValue currency={currency} value={total} />
            )}
            sorter
          />
        )}
        {(permissions?.includes(ActionRbacEnum.FINANCIAL_BALANCE_READ) ||
          permissions?.includes(ActionRbacEnum.FINANCIAL_BALANCE_WRITE)) && (
          <Table.Column
            title={t('invoice.table.actions')}
            render={(_, { id, status }: InvoiceModel) => (
              <Space>
                {permissions?.includes(
                  ActionRbacEnum.FINANCIAL_BALANCE_WRITE,
                ) && (
                  <Button
                    disabled={
                      !(
                        status === StatusEnum.PartlyPaid ||
                        status === StatusEnum.Unpaid ||
                        status === StatusEnum.Overdue
                      )
                    }
                    onClick={() => handleOnClickCreateInvoicePayment(id)}
                  >
                    {t('invoice.table.pay')}
                  </Button>
                )}
                {permissions?.includes(
                  ActionRbacEnum.FINANCIAL_BALANCE_READ,
                ) && (
                  <Styled.ViewButton
                    icon={<EyeOutlinedIcon />}
                    onClick={() => handleOnClickEditInvoice(id)}
                  />
                )}
              </Space>
            )}
          />
        )}
      </Table>
    </>
  );
};

export { InvoiceListContainer as default, InvoiceListContainer };
