import { EyeOutlined } from '@ant-design/icons';
import { List } from '@refinedev/antd';
import { Button, Space, Table } from 'antd';
import { PageTitle } from 'common/components';
import { usePermissionsBalance } from 'common/hooks/use-permissions-balance.hook';
import {
  CategoryIcon,
  CropIcon,
  DateIcon,
  FarmIcon,
  ItemsIcon,
  MovementsIcon,
  WarehouseIcon,
} from 'common/icons';
import {
  ListFiltersModel,
  RefineTableFilters,
  RefineTableFiltersEnum,
  useRefineTableFilters,
} from 'common/refine-table-filters';
import { ActionRbacEnum } from 'common/utils/auth-provider.util';
import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useOrganization } from 'src/organization';

import { SelectItemsMovements } from '../select-items-movements/select-items-movements.component';
import StockBalance from '../stock-summary/stock-balance/stock-balance.component';
import { ColoredValue } from './colored-value/colored-value.component';
import { DateColumn } from './date-column/date-column.component';
import MovementsDetailsDrawer from './details-drawer/details-drawer.component';
import { MovementTag } from './movement-tag/movement-tag.component';
import { MovementType } from './movements.enum';
import { MovementsModel } from './movements.model';

const MovementsContainer = () => {
  const location = useLocation();
  const states = location.state as {
    pageName: string;
    farm?: string;
    warehouse?: string;
    itemCode?: string;
    itemName?: string;
  };
  const [pageName, setPageName] = useState<string>(states?.pageName);
  const [startFarm, setStartFarm] = useState<string | undefined>(states?.farm);
  const [startWarehouse, setStartWarehouse] = useState<string | undefined>(
    states?.warehouse,
  );
  const [startItemCode, setStartItemCode] = useState<string | undefined>(
    states?.itemCode,
  );
  const [startItemName, setStartItemName] = useState<string | undefined>(
    states?.itemName,
  );
  const [selectedVoucherNo, setSelectedVoucherNo] = useState<string>('');
  const [detailsDrawerVisible, setDetailsDrawerVisible] =
    useState<boolean>(false);
  const { permissions } = usePermissionsBalance();

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { currentOrganizationId } = useOrganization();

  useEffect(() => {
    if (states) {
      setPageName(states.pageName);
      setStartFarm(states.farm);
      setStartWarehouse(states.warehouse);
      setStartItemCode(states.itemCode);
      setStartItemName(states.itemName);
    }
  }, [states]);

  const stockMetaData = useMemo(
    () => ({
      organizationId: currentOrganizationId,
    }),
    [currentOrganizationId],
  );

  const optionsMovement = useMemo(
    () =>
      Object.values(MovementType).map((value) => ({
        label: t(`stock.movements.tag.${value}`),
        value,
      })),
    [t],
  );

  const defaultListFilters: ListFiltersModel[] = useMemo(
    () => [
      {
        label: t('filter.farm'),
        field: 'farm',
        type: RefineTableFiltersEnum.SELECT,
        resource: 'farm',
        optionLabel: 'name',
        show: true,
        initialValue: startFarm,
        icon: <FarmIcon />,
      },
      {
        label: t('filter.warehouse'),
        field: 'warehouse',
        type: RefineTableFiltersEnum.SELECT,
        resource: 'warehouse',
        optionLabel: 'name',
        show: true,
        parentsField: ['farm'],
        initialValue: startWarehouse,
        icon: <WarehouseIcon />,
      },
      {
        label: t('filter.item'),
        field: 'itemCode',
        type: RefineTableFiltersEnum.SELECT,
        resource: 'item',
        optionLabel: 'name',
        initialValue: startItemCode
          ? {
              label: startItemName ?? '',
              value: startItemCode ?? '',
            }
          : undefined,
        show: true,
        icon: <ItemsIcon />,
      },
      {
        label: t('filter.field'),
        field: 'project',
        type: RefineTableFiltersEnum.MULTIPLE_SELECT,
        resource: 'crop-cycle',
        optionLabel: 'projectName',
        show: false,
        icon: <CropIcon />,
      },
      {
        label: t('filter.category'),
        field: 'itemCategoryId',
        type: RefineTableFiltersEnum.SELECT,
        resource: 'item-category',
        show: false,
        icon: <CategoryIcon />,
      },
      {
        label: t('filter.movement_type'),
        field: 'movement',
        type: RefineTableFiltersEnum.MULTIPLE_SELECT,
        options: optionsMovement,
        show: false,
        icon: <MovementsIcon />,
      },
      {
        label: t('filter.start_date'),
        field: 'startDate',
        type: RefineTableFiltersEnum.DATE,
        initialValue: dayjs().subtract(6, 'month').format('YYYY-MM-DD'),
        show: true,
        icon: <DateIcon />,
      },
      {
        label: t('filter.end_date'),
        field: 'endDate',
        type: RefineTableFiltersEnum.DATE,
        initialValue: dayjs().format('YYYY-MM-DD'),
        show: true,
        icon: <DateIcon />,
      },
    ],
    [
      t,
      startFarm,
      startWarehouse,
      startItemName,
      startItemCode,
      optionsMovement,
    ],
  );

  const { tableProps, searchFormProps } = useRefineTableFilters<MovementsModel>(
    {
      listFilters: defaultListFilters,
      resource: 'movement',
      syncWithLocation: true,
      meta: stockMetaData,
      filters: {
        initial: [
          {
            field: 'viewGroup',
            operator: 'eq',
            value: true,
          },
          {
            field: 'farm',
            operator: 'eq',
            value: startFarm,
          },
          {
            field: 'warehouse',
            operator: 'eq',
            value: startWarehouse,
          },
          {
            field: 'itemCode',
            operator: 'eq',
            value: startItemCode,
          },
        ],
      },
    },
  );

  const handleOpenDrawer = (id: string) => {
    setSelectedVoucherNo(id);
    setDetailsDrawerVisible(true);
  };

  const handleCloseDrawer = () => {
    setSelectedVoucherNo('');
    setDetailsDrawerVisible(false);
  };

  const farmName = searchFormProps?.form?.getFieldValue('farm');
  const warehouseId = searchFormProps?.form?.getFieldValue('warehouse');

  const hasStockBalance = useMemo(() => {
    return (
      permissions?.includes(ActionRbacEnum.FINANCIAL_BALANCE_READ) &&
      (farmName || warehouseId)
    );
  }, [farmName, permissions, warehouseId]);

  return (
    <div>
      <PageTitle
        title={t('stock_summary.title', {
          pageName,
        })}
        hasBackButton
        onBack={() => navigate(-2)}
        buttons={
          hasStockBalance && (
            <StockBalance farmId={farmName} warehouseId={warehouseId} />
          )
        }
      />
      <MovementsDetailsDrawer
        onClose={handleCloseDrawer}
        isOpen={detailsDrawerVisible}
        voucherNo={selectedVoucherNo}
      />
      <List>
        <RefineTableFilters
          searchFormProps={searchFormProps}
          initialListFilters={defaultListFilters}
          leftItems={<SelectItemsMovements isMovements />}
        />
        <Table
          {...tableProps}
          pagination={{
            ...tableProps.pagination,
            showSizeChanger: true,
            pageSizeOptions: [10, 20, 50, 100],
          }}
          scroll={{ y: 440, x: 870 }}
        >
          <Table.Column
            title={t('stock.movements.table.date')}
            render={(_, { postingDateView }: MovementsModel) => (
              <DateColumn date={postingDateView} />
            )}
          />
          <Table.Column
            title={t('stock.movements.table.type')}
            render={(_, { movementView, quantity }: MovementsModel) => (
              <MovementTag movementType={movementView} quantity={quantity} />
            )}
            width={170}
          />
          <Table.Column
            dataIndex="warehouse"
            title={t('stock.movements.table.warehouse')}
          />
          <Table.Column
            dataIndex="itemName"
            title={t('stock.movements.table.item')}
          />
          <Table.Column
            title={t('stock.movements.table.quantity')}
            render={(_, { stockUom, quantity }: MovementsModel) => (
              <ColoredValue value={quantity} stockUom={stockUom} />
            )}
          />
          <Table.Column
            title={t('stock.movements.table.balance')}
            render={(_, { stockUom, balanceQty }: MovementsModel) => (
              <ColoredValue value={balanceQty} stockUom={stockUom} />
            )}
          />
          <Table.Column
            title={t('stock.movements.table.action')}
            render={(_, { voucherNo }: MovementsModel) => (
              <Space>
                <Button
                  icon={<EyeOutlined />}
                  onClick={() => handleOpenDrawer(voucherNo)}
                />
              </Space>
            )}
          />
        </Table>
      </List>
    </div>
  );
};

export { MovementsContainer as default, MovementsContainer };
