import { useSelect } from '@refinedev/antd';
import { Space } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { SelectWithButton } from 'common/components';
import { CheckIcon, EditIcon, HelpIcon } from 'common/icons';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReceivedPaidWarehouseTestId } from 'src/orders-and-payments/electronic-invoice/electronic-invoice-test-id.constants';
import { useOrganization } from 'src/organization';
import { IsGroupWarehouseEnum } from 'src/stock/warehouse-list';

import * as Styled from './received-paid-warehouse.style';

const ReceivedPaidWarehouse = () => {
  const formInstance = useFormInstance();
  const warehouse = useWatch(['warehouse']);
  const farm = useWatch(['farm']);
  const [openEdit, setOpenEdit] = useState(false);
  const [hasSearchValue, setHasSearchValue] = useState(false);
  const { t } = useTranslation();
  const { currentOrganizationId } = useOrganization();
  const metadata = useMemo(
    () => ({
      organizationId: currentOrganizationId,
    }),
    [currentOrganizationId],
  );

  const { selectProps: warehouseSelectProps, query } = useSelect({
    optionLabel: 'name',
    optionValue: 'id',
    resource: 'warehouse',
    filters: [
      {
        field: 'farm',
        operator: 'eq',
        value: farm,
      },
      {
        field: 'isGroup',
        operator: 'eq',
        value: IsGroupWarehouseEnum.NO,
      },
    ],
    onSearch: (value) => {
      setHasSearchValue(!!value);

      return [
        {
          field: 'name',
          operator: 'eq',
          value: value,
        },
      ];
    },
    meta: metadata,
    queryOptions: {
      enabled: !!metadata.organizationId,
    },
  });

  useEffect(() => {
    if (!hasSearchValue && query.data?.data?.length === 1) {
      formInstance.setFieldsValue({ warehouse: query.data.data[0].name });
    }
  }, [hasSearchValue, query.data]);

  const handleChangeOpenEdit = () => {
    setOpenEdit(!openEdit);
  };

  return (
    <Styled.WarehouseBox>
      {t('electronic_invoice.received_paid.question_warehouse')}
      <Space>
        <Styled.FormItem
          name={['warehouse']}
          rules={[
            {
              required: true,
            },
          ]}
        >
          {openEdit ? (
            <SelectWithButton
              {...warehouseSelectProps}
              style={{ width: 360 }}
              defaultVisible
              data-testid={
                ReceivedPaidWarehouseTestId.RECEIVED_PAID_WAREHOUSE_SELECT
              }
            />
          ) : (
            <Styled.BoxWarehouse
              isSuccess={!!warehouse}
              onClick={handleChangeOpenEdit}
            >
              {warehouse ? (
                <>
                  <CheckIcon />
                  {warehouse}
                </>
              ) : (
                <>
                  <HelpIcon />
                  {t('electronic_invoice.received_paid.click_warehouse')}
                </>
              )}
            </Styled.BoxWarehouse>
          )}
        </Styled.FormItem>
        <Styled.EditButton
          data-testid={
            ReceivedPaidWarehouseTestId.RECEIVED_PAID_WAREHOUSE_BUTTON
          }
          onClick={handleChangeOpenEdit}
        >
          {openEdit ? <CheckIcon /> : <EditIcon />}
        </Styled.EditButton>
      </Space>
    </Styled.WarehouseBox>
  );
};

export { ReceivedPaidWarehouse };
