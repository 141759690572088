import { Button, Form, Select } from 'antd';
import styled from 'styled-components';
import { GreenColors, NeutralColors, YellowColors } from 'theme';

interface BoxProps {
  isSuccess: boolean;
}

const Box = styled.div<BoxProps>`
  width: 722px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${(props) =>
    props.isSuccess ? GreenColors[10] : YellowColors[10]};
  border-radius: 12px 0 12px 12px;
  margin-bottom: 8px;
  padding: 0 16px;
`;

const FormItem = styled(Form.Item)`
  margin: 0;
`;

const EditButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border-radius: 16px;
  width: 32px;
  height: 32px;

  svg {
    width: 16px;
    fill: ${NeutralColors[80]};
  }
`;

const SelectWarehouse = styled(Select)`
  margin: 0 !important;
`;

const BoxWarehouse = styled.div<BoxProps>`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 16px 0;
  background-color: ${(props) =>
    props.isSuccess ? GreenColors[10] : YellowColors[10]};
  height: 52px;
  border-radius: 8px;
  width: 360px;

  svg {
    color: ${(props) => (props.isSuccess ? GreenColors[60] : YellowColors[60])};
  }
`;

export { Box, BoxWarehouse, EditButton, FormItem, SelectWarehouse };
