import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { Input as InputAntd } from 'antd';
import styled from 'styled-components';
import { NeutralColors } from 'theme';

interface ContainerProps {
  hideMargin?: boolean;
}

const ContainerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  align-items: center;
`;

const Container = styled.div<ContainerProps>`
  margin-bottom: ${(props) => (props.hideMargin ? '0' : '16px')};
  margin-top: ${(props) => (props.hideMargin ? '8px' : '0')};
`;

const Input = styled(InputAntd)`
  max-width: 240px;
`;

const IconPlus = styled(PlusOutlined)`
  color: ${NeutralColors[40]};
`;

const IconClose = styled(CloseOutlined)`
  color: ${NeutralColors[40]};
`;

export { Container, ContainerHeader, IconClose, IconPlus, Input };
