import {
  IDataContextProvider,
  IDataMultipleContextProvider,
  IRefineOptions,
} from '@refinedev/core/dist/interfaces';

import { apiUrlOptions } from './api.config';
import { refineDataProvider } from './refine-data.provider';
import { refineSimpleRestDataProvider } from './refine-simple-rest-data.provider';

interface RefineConfigProps {
  dataProvider: IDataContextProvider | IDataMultipleContextProvider;
  options: IRefineOptions;
}

const AMOUNT_OF_RETRIES = 3;

const refineConfig: RefineConfigProps = {
  dataProvider: {
    default: refineDataProvider(`${apiUrlOptions.fms}/v1`),
    defaultV2: refineDataProvider(`${apiUrlOptions.fms}/v2`),
    simpleRest: refineSimpleRestDataProvider(`${apiUrlOptions.fms}/v1`),
    platformV2: refineSimpleRestDataProvider(`${apiUrlOptions.base}/v2`),
    platformV3: refineSimpleRestDataProvider(`${apiUrlOptions.base}/v3`),
  },
  options: {
    syncWithLocation: true,
    warnWhenUnsavedChanges: false,
    disableTelemetry: true,
    reactQuery: {
      clientConfig: {
        defaultOptions: {
          queries: {
            retry: (
              failureCount: number,
              error: { config: { method: string } },
            ) => {
              if (
                error?.config?.method === 'get' &&
                failureCount < AMOUNT_OF_RETRIES
              ) {
                return true;
              }
              return false;
            },
          },
        },
      },
    },
  },
};

export { refineConfig as default, refineConfig };
