import { useTranslation } from 'react-i18next';
import { GreenColors, NeutralColors, RedColors } from 'theme';

import { StatusColumnTestId } from '../material-consumption.constants';
import { MaterialConsumptionEnum } from '../status.enum';
import * as Styled from './status-column.style';

interface StatusColumnProps {
  statusId: number;
}

const StatusColumn = ({ statusId }: StatusColumnProps) => {
  const { t } = useTranslation();

  switch (String(statusId)) {
    case MaterialConsumptionEnum.DRAFT:
      return (
        <Styled.Tag
          data-testid={StatusColumnTestId.STATUS_COLUMN_DRAFT}
          textColor={NeutralColors[70]}
        >
          {t('material_consumption.status.draft')}
        </Styled.Tag>
      );
    case MaterialConsumptionEnum.CANCELLED:
      return (
        <Styled.Tag
          data-testid={StatusColumnTestId.STATUS_COLUMN_CANCELLED}
          textColor={RedColors[50]}
        >
          {t('material_consumption.status.cancelled')}
        </Styled.Tag>
      );
    case MaterialConsumptionEnum.CONFIRMED:
    default:
      return (
        <Styled.Tag
          data-testid={StatusColumnTestId.STATUS_COLUMN_CONFIRMED}
          textColor={GreenColors[50]}
        >
          {t('material_consumption.status.confirmed')}
        </Styled.Tag>
      );
  }
};

export { StatusColumn };
